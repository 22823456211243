import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Table, Button, message, Spin } from 'antd'; // Include Spin for loading
import { getBackups, getFile } from '../../services/backup';
import axios from 'axios';
const BackupList = () => {
  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false); // Track fetch errors

  useEffect(() => {
    const fetchBackups = async () => {
      try {
        const backupData = await getBackups();
        setBackups(backupData.data);
        setFetchError(false); // Reset fetch error flag
      } catch (error) {
        message.error('Failed to fetch backups');
        setFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchBackups();
  }, []);

  const columns = [
    {
      title: 'Download',
      dataIndex: 'path',
      key: 'path',
      render: (path, record) => (
        <Button
          onClick={() => handleDownload(record.path)}
          type="primary"
          icon={<DownloadOutlined />}
          disabled={!record.path} // Disable button if no path
        >
          Download
        </Button>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
  ];

  const handleDownload = async (fileName) => {
   
    if (fileName) {
      try {
        const fileNameWithExtension = fileName.split('/').pop();
        const response = await getFile(fileNameWithExtension);
        console.log(fileNameWithExtension);
        console.log(response);
        
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileNameWithExtension);  
        document.body.appendChild(link);
        link.click();  
    
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('There was an error downloading the file:', error);
      }

    } else {
      message.error('File not available for download');
    }
  };

  return (
    <div>
      {loading && !fetchError && <Spin tip="Loading backups..." />} {/* Show spinner while loading */}
      {fetchError && <div>Failed to load backups. Please try again later.</div>} {/* Show error message */}
      {!loading && !fetchError && (
        <Table
          columns={columns}
          dataSource={backups}
          rowKey={(record) => record.path}
        />
      )}
    </div>
  );
};

export default BackupList;