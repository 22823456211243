import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Row, Col, Card, Badge, notification, Tag, Spin } from "antd";
import { FormOutlined } from "@ant-design/icons";
import DefaultLayout from "../../components/layout/DefaultLayout";
import Header from "../../components/header";
import equipmentRequestService from "../../services/equipmentRequest";
import approvalService from "../../services/approver";
import CommentSystem from "../../components/comment";
import CreatedByComponent from "../../components/commonDetails/createdBy";
import ApproversComponent from "../../components/commonDetails/approvers";
import InventoryShowComponent from "../../components/commonDetails/inventoryShow";
import CurrencyShowComponent from "../../components/commonDetails/currencyShow";
import ApproveDisapproveComponent from "../../components/commonDetails/approveDisapprove";
import TaskStatus from "../../components/commonDetails/taskStatus";
import FeedbackModal from "../../components/feedbackModal";

const EquipmentRequestDetails = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState(null);
  const { id } = useParams();
  const [assignedUserId, setAssignedUserId] = useState(null);
  const navigate = useNavigate();
  const [formId] = useState(4);

  const getEquipmentsRequestById = async () => {
    setLoading(true);
    await equipmentRequestService
      .getEquipmentsRequestById(id)
      .then((response) => {
        console.log(response);
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/crf");
      });
  };

  useEffect(() => {
    getEquipmentsRequestById();
  }, []);

  const handleSubmit = async (values, isParallel) => {
    setLoading(true);
    const formattedValues = {
      status,
      approved_disapproved: false,
      form_id: 4,
      key: parseInt(id),
      reason: values.message,
      ...(isParallel && { assigned_user_id: assignedUserId }),
    };

    const serviceMethod = isParallel
      ? approvalService.updateParallelStatus
      : approvalService.updateStatus;

    try {
      const response = await serviceMethod(formattedValues);
      if (response.success) {
        notification.success({
          message: "Status",
          description: response.message,
        });
        form.resetFields();
        getEquipmentsRequestById(id);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.response?.data?.message || "An error occurred",
      });
    } finally {
      navigate("/dashboard");
      setLoading(false);
    }
  };

  return (
    <DefaultLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header icon={<FormOutlined />} title="CRF Request Details" />
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Card style={{ minHeight: "100vh" }}>
          <Row gutter={[24, 24]}>
            <Col lg={5} md={5} sm={24} xs={24}>
              <CreatedByComponent data={data} />
              <ApproversComponent approvers={data && data.approvers} />
            </Col>

            <Col lg={19} md={19} sm={24} xs={24}>
              <div>
                {data?.approved_disapproved === true && (
                  <div
                    style={{
                      position: "sticky",
                      top: 1,
                      zIndex: 999,
                      background: "#fff",
                      padding: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    <Spin spinning={loading}>
                      {data?.task_status?.id !== null && (
                        <FeedbackModal
                          title="QA Feedback"
                          formId={formId}
                          style={{ marginright: "0" }}
                        />
                      )}
                      <b>Approval Request</b>
                      <ApproveDisapproveComponent
                        form={form}
                        handleStatus={(values) => handleSubmit(values, false)}
                        loading={loading}
                        setStatus={setStatus}
                      />
                    </Spin>
                  </div>
                )}

                {data?.parallel_approved_disapproved === true && (
                  <div
                    style={{
                      position: "sticky",
                      top: data?.approved_disapproved ? "150px" : "1px", // Adjust the top value as needed
                      zIndex: 999,
                      background: "#fff", // Add a background color
                      padding: "5px", // Add padding for better visual separation
                      borderRadius: "4px", // Optional: Add rounded corners
                    }}
                  >
                    <Spin spinning={loading}>
                      <b>Parallel Approval Request</b>
                      <ApproveDisapproveComponent
                        form={form}
                        handleStatus={(values) => handleSubmit(values, true)}
                        loading={loading}
                        setStatus={setStatus}
                        parallel={true}
                        parallelUsers={
                          data && data.parallel_approved_disapproved_users
                        }
                        setAssignedUserId={setAssignedUserId}
                        reloadData={getEquipmentsRequestById}
                      />
                    </Spin>
                  </div>
                )}

                <div className="mb-10">
                  {data?.assigned_task_to_me === true && (
                    <div
                      style={{
                        position: "sticky",
                        top: "1px",
                        zIndex: 999,
                        background: "#fff", // Add a background color
                        padding: "5px", // Add padding for better visual separation
                        borderRadius: "4px", // Optional: Add rounded corners
                      }}
                    >
                      <TaskStatus
                        data={data}
                        reloadData={getEquipmentsRequestById}
                        formId={formId}
                      />
                    </div>
                  )}
                </div>

                {data && data.request_title && (
                  <Badge.Ribbon text={`Grant Total ${data.grand_total} PKR `}>
                    <Card title="Basic Information" className="mb-10">
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                          <p className="fs-12 mb-10">Sequence No</p>
                          <Tag>{data.sequence_no}</Tag>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                          <p className="fs-12 mb-10">Request Title</p>
                          <Tag>{data.request_title}</Tag>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                          <p className="fs-12 mb-10">Cost Center</p>
                          <Tag>{data.cost_center_id?.cost_center}</Tag>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                          <p className="fs-12 mb-10">Location</p>
                          <Tag>{data.location?.name}</Tag>
                        </Col>

                        {data.task_status?.name && (
                          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <p className="fs-12 mb-10 bold">Task Status</p>
                            <Tag>{data.task_status.name}</Tag>
                          </Col>
                        )}

                      </Row>
                    </Card>
                  </Badge.Ribbon>
                )}

                {data && data.equipmentRequests && (
                  <Card title="Equipment Information" className="mb-10">
                    {data.equipmentRequests.map((equipmentRequest, index) => (
                      <Badge.Ribbon
                        key={index}
                        text={`Equipment Request ${index + 1}`}
                      >
                        <Card>
                          <Row key={index} gutter={[16, 16]} className="mt-20">
                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                              <p className="fs-12 mb-10">Equipment Name</p>
                              <Tag label="Equipment Name">
                                {equipmentRequest.equipment.name}
                              </Tag>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                              <p className="fs-12 mb-10">Quantity</p>
                              <Tag label="Quantity">{equipmentRequest.qty}</Tag>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                              <p className="fs-12 mb-10">State</p>
                              <Tag label="State">
                                {equipmentRequest.state.name}
                              </Tag>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                              <p className="fs-12 mb-10">Expense Type</p>
                              <Tag label="Expense Type">
                                {equipmentRequest.expense_type.name}
                              </Tag>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                              <p className="fs-12 mb-10">Expense Nature</p>
                              <Tag label="Expense Nature">
                                {equipmentRequest.expense_nature.name}
                              </Tag>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                              <p className="fs-12 mb-10">
                                Business Justification
                              </p>
                              <Tag label="Business Justification">
                                {equipmentRequest.business_justification}
                              </Tag>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <CurrencyShowComponent data={equipmentRequest} />
                            </Col>

                            <Col span={24}>
                              {equipmentRequest.asset_details &&
                                JSON.parse(equipmentRequest.asset_details).map(
                                  (assetDetail, innerIndex) => (
                                    <div key={innerIndex} className="mt-14">
                                      <Tag color="blue" className="fs-10">
                                        Equipment Inventory Asset Details{" "}
                                        {innerIndex + 1}
                                      </Tag>
                                      <Card
                                        key={innerIndex}
                                        label="Equipment Inventory Asset Details"
                                        className="mb-10"
                                      >
                                        <InventoryShowComponent
                                          key={innerIndex}
                                          assetDetail={assetDetail}
                                        />
                                      </Card>
                                    </div>
                                  )
                                )}
                            </Col>
                          </Row>
                        </Card>
                      </Badge.Ribbon>
                    ))}
                  </Card>
                )}

                {data &&
                  data.softwareRequests &&
                  data.softwareRequests.length > 0 && (
                    <Card title="Software Information" className="mt-20 mb-10">
                      {data.softwareRequests.map((softwareRequest, index) => (
                        <Badge.Ribbon
                          key={index}
                          text={`Software Request ${index + 1}`}
                        >
                          <Card>
                            <Row
                              key={index}
                              gutter={[16, 16]}
                              className="mt-20"
                            >
                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Software Name</p>
                                <Tag>{softwareRequest.name}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Software Version</p>
                                <Tag>{softwareRequest.version}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Quantity</p>
                                <Tag>{softwareRequest.qty}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Expense Type</p>
                                <Tag>{softwareRequest.expense_type.name}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Expense Nature</p>
                                <Tag>{softwareRequest.expense_nature.name}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">
                                  Business Justification
                                </p>
                                <Tag>
                                  {softwareRequest.business_justification}
                                </Tag>
                              </Col>

                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <CurrencyShowComponent data={softwareRequest} />
                              </Col>

                              <Col span={24}>
                                {softwareRequest.asset_details &&
                                  JSON.parse(softwareRequest.asset_details).map(
                                    (assetDetail, innerIndex) => (
                                      <div key={innerIndex} className="mt-14">
                                        <Tag color="blue" className="fs-10">
                                          Software Inventory Asset Details{" "}
                                          {innerIndex + 1}
                                        </Tag>
                                        <Card
                                          key={innerIndex}
                                          label="Software Inventory Asset Details"
                                          className="mb-10"
                                        >
                                          <InventoryShowComponent
                                            key={innerIndex}
                                            assetDetail={assetDetail}
                                          />
                                        </Card>
                                      </div>
                                    )
                                  )}
                              </Col>
                            </Row>
                          </Card>
                        </Badge.Ribbon>
                      ))}
                    </Card>
                  )}

                {data &&
                  data.serviceRequests &&
                  data.serviceRequests.length > 0 && (
                    <Card title="Service Information" className="mt-20 mb-10">
                      {data.serviceRequests.map((serviceRequest, index) => (
                        <Badge.Ribbon
                          key={index}
                          text={`Service Information ${index + 1}`}
                        >
                          <Card>
                            <Row
                              key={index}
                              gutter={[16, 16]}
                              className="mt-20"
                            >
                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Service Name</p>
                                <Tag>{serviceRequest.name}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">State</p>
                                <Tag>{serviceRequest.state.name}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Expense Type</p>
                                <Tag>{serviceRequest.expense_type.name}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">Expense Nature</p>
                                <Tag>{serviceRequest.expense_nature.name}</Tag>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <p className="fs-12 mb-10">
                                  Business Justification
                                </p>
                                <Tag>
                                  {serviceRequest.business_justification}
                                </Tag>
                              </Col>

                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <CurrencyShowComponent data={serviceRequest} />
                              </Col>

                              <Col span={24}>
                                {serviceRequest.asset_details &&
                                  JSON.parse(serviceRequest.asset_details).map(
                                    (assetDetail, innerIndex) => (
                                      <div key={innerIndex} className="mt-14">
                                        <Tag color="blue" className="fs-10">
                                          Service Inventory Asset Details{" "}
                                          {innerIndex + 1}
                                        </Tag>
                                        <Card
                                          key={innerIndex}
                                          label="Service Inventory Asset Details"
                                          className="mb-10"
                                        >
                                          <InventoryShowComponent
                                            key={innerIndex}
                                            assetDetail={assetDetail}
                                          />
                                        </Card>
                                      </div>
                                    )
                                  )}
                              </Col>
                            </Row>
                          </Card>
                        </Badge.Ribbon>
                      ))}
                    </Card>
                  )}
              </div>

              <CommentSystem
                id={id}
                form_id={4}
                status={data && data.status}
                comment_status={data && data.comment_status}
              />
            </Col>
          </Row>
        </Card>
      </Spin>
    </DefaultLayout>
  );
};

export default EquipmentRequestDetails;
